import { gql } from '@apollo/client';

export default gql`
	query VIDEOS(
		$category: String
		$edition: EDITIONKEY!
		$season: String
		$skip: Int
		$limit: Limit
	) {
		videos(
			filter: {
				category: $category
				edition: $edition
				season: $season
				skip: $skip
				limit: $limit
			}
		) {
			nodes {
				type
				snipe
				shortTitle
				id
				title
				publishDate
				duration
				uri(edition: $edition)
				season
				thumbnail {
					uri
					id
				}
			}
			totalCount
		}
	}
`;
