import { gql } from '@apollo/client';
import FragmentArticleData from './FRAGMENT_ARTICLE_DATA';
import FragmentGalleryData from './FRAGMENT_GALLERY_DATA';
import FragmentVideoData from './FRAGMENT_VIDEO_DATA';
import FragmentGalleryFolderData from './FRAGMENT_GALLERY_FOLDER_DATA';

export default gql`
	${FragmentArticleData}
	${FragmentGalleryData}
	${FragmentVideoData}
	${FragmentGalleryFolderData}
	query SHOW_BY_KEY($showKey: String!, $edition: EDITIONKEY!) {
		showByKey(key: $showKey, edition: $edition) {
			id
			name
			uri(edition: $edition)
			category(edition: $edition) {
				name
			}
			pageMetaData {
				defaultUrl
				canonicalUrl
				redirectUrl
				hreflang {
					edition
					uri
				}
			}
			thumbnail(edition: $edition) {
				uri
				id
			}
			brandingImage(edition: $edition) {
				uri
			}
			logo(edition: $edition) {
				uri
				sourceWidth
				sourceHeight
			}
			youtubeAccountUrl
			facebookAccountUrl
			instagramAccountUrl
			twitterAccountUrl
			brandHexColor1
			tuneIn
			fullEpisodesLink
			seasonStart
			seasonEnd
			promo {
				...VideoData
			}
			page(edition: $edition) {
				name
				metaTitleOverride
				metaDescriptionOverride
				layout {
					adKeywords
					contentFeedEnabled
					sections {
						id
						sectionType
						widgets {
							id
							name
							timestampsEnabled
							authorBylineEnabled
							header
							headerImage {
								id
								title
								uri
							}
							isCurated
							criteria {
								contentType
								category(edition: $edition) {
									displayName(edition: $edition)
									key
								}
								dataType
							}
							contentList(edition: $edition) {
								displayTitle
								displayImage(edition: $edition) {
									id
									title
									uri
									sourceWidth
									sourceHeight
								}
								linkType
								reference {
									id
									uri(edition: $edition)
									...ArticleData
									...GalleryData
									...VideoData
									...GalleryFolderData
								}
							}
							embedHtml
							widgetType
							widgetTemplate
							brandedTextOverride
							advertiserLogoOverride
							widgetAdKeyword
							brandingType
							additionalContentLink {
								reference {
									uri(edition: $edition)
									title
								}
							}
							coverflowBackgroundImage {
								id
								uri
							}
						}
					}
				}
			}
		}
	}
`;
