import React from 'react';
import { string, number, bool } from 'prop-types';
import { useQuery } from '@apollo/client';
import { usePageContext } from '@/components/layout/page/PageContext';
import ShowThumb from './ShowThumb';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import NAV from '@/graphql/queries/NAV';
import './FeaturedShows.scss';

/**
 * @function FeaturedShows
 * @description Component that queries + renders a list of featured shows
 * @param {String} [width] width of the show thumbnail
 * @param {String} [height] height of the show thumbnail
 * @param {String} [className] class name affixed to each ShowThumb element to determine column sizing
 * @param {Number} [limit] the maximum amount of shows to display
 * @param {Boolean} [hasH1Tag] whether a h1 tag is desired or not
 * @returns {React.ReactElement}
 */
const FeaturedShows = ({ width, height, className, limit, hasH1Tag }) => {
	const { uppercaseEdition, translations } = usePageContext();

	// Get Featured Shows data
	const { loading, error, data } = useQuery(NAV, {
		variables: { id: uppercaseEdition }
	});

	// Handle loading state
	if (loading) return <p>Loading...</p>;

	// Handle error state - log error and don't render
	if (error) {
		console.error(`Error! ${error.message}`);
		return null;
	}

	// Handle empty data payloads returned from GraphQL
	if (!data || !data.nav || !data.nav.shows || !data.nav.shows.links) {
		return null;
	}

	// Get data needed for UI
	const shows = data.nav.shows.links.slice(0, limit);

	return shows && shows.length > 0 ? (
		<div className="featured-shows" data-hook="featured-shows">
			<WidgetHeader
				title={translations.FEATURED_SHOWS || 'Featured Shows'}
				hasH1Tag={hasH1Tag}
			/>
			<div className="columns is-mobile is-multiline">
				{shows.map(({ displayTitle, reference }, index) => {
					return (
						reference && (
							<ShowThumb
								key={`featured-show-${index}`}
								title={displayTitle}
								uri={reference.uri}
								thumbnail={reference.thumbnail}
								width={width}
								height={height}
								tuneIn={reference.tuneIn}
								className={className}
							/>
						)
					);
				})}
			</div>
		</div>
	) : null;
};

FeaturedShows.defaultProps = {
	limit: 6,
	className: 'is-6-mobile is-4-tablet is-2-desktop',
	width: '390',
	height: '520',
	hasH1Tag: false
};

FeaturedShows.propTypes = {
	width: string,
	height: string,
	className: string,
	limit: number,
	hasH1Tag: bool
};

FeaturedShows.displayName = 'FeaturedShows';

export default FeaturedShows;
