import React from 'react';
import { object } from 'prop-types';
import StructuredData from '@/components/structured-data/StructuredData';

/**
 * @function ShowDetailStructuredData
 * @description compiles the structured data for show detail pages
 * @param {Object} props.showData
 * @returns {React.ReactElement}
 */
const ShowDetailStructuredData = ({ showData }) => {
	if (!showData) return null;

	const { logo, name, pageMetaData } = showData;
	const pageUrl = pageMetaData?.canonicalUrl;

	const structuredData = {
		'@type': 'TVSeries',
		mainEntityOfPage: pageUrl,
		name,
		publisher: {
			'@type': 'Organization',
			name: 'E! News',
			logo: {
				'@type': 'ImageObject',
				url: logo?.uri,
				width: logo?.sourceWidth,
				height: logo?.sourceHeight
			}
		}
	};

	return <StructuredData data={structuredData} />;
};

ShowDetailStructuredData.propTypes = {
	showData: object.isRequired
};

ShowDetailStructuredData.displayName = 'ShowDetailStructuredData';

export default ShowDetailStructuredData;
