import React from 'react';
import Translate from '@/components/i18n/Translate';
import { string } from 'prop-types';
import './NoContent.scss';

/**
 * @function NoContent
 * @description returns in the case of no videos available
 * @param {String} season the season number
 * @returns {React.ReactElement}
 */
const NoContent = ({ season }) => {
	return (
		<div className="column is-12 text--md show-clips__no-content">
			<Translate word="NO_CONTENT_FOR" /> <Translate word="SEASON" /> {season}
		</div>
	);
};

NoContent.propTypes = {
	season: string
};

NoContent.displayName = 'NoContent';

export default NoContent;
