import React, { useContext } from 'react';
import { string, array, object, oneOfType } from 'prop-types';
import { PageContext } from '@/components/layout/page/PageContext';
import Thumb from '@/components/thumb/Thumb';
import intlUrl from '@/components/i18n/intlUrl';
import parseHtml from '@/utils/parseHtml';
import './ShowThumb.scss';

/**
 * @function ShowThumb
 * @description A component to render an individual Featured Show
 * @param {string} title The show title
 * @param {string} uri The canonical URL of the show's page
 * @param {object} thumbnail The 3x4 "box art" image for the show
 * @param {String|Array} [tuneIn] the show time
 * @param {String} [width] width of the show thumbnail
 * @param {String} [height] height of the show thumbnail
 * @param {String} [className] class name affixed to each ShowThumb element to determine column sizing;
 * default is 6 per row desktop & tablet, 2 per row mobile
 * @returns {React.ReactElement}
 */
export const ShowThumb = ({ title, uri, thumbnail, tuneIn, width, height, className }) => {
	// Don't render if no image is provided
	if (!thumbnail || !thumbnail.uri) return null;

	const { edition } = useContext(PageContext);

	return title ? (
		<div className={`column ${className}`} data-hook="show-thumb">
			<div className="show-thumb">
				<div className="show-thumb__image">
					<Thumb
						href={intlUrl(edition, uri)}
						thumbnail={thumbnail}
						width={width}
						height={height}
						title={title}
						withLink={true}
						type="3x4"
					/>
				</div>
				<h5 className="show-thumb__title" data-hook="show-thumb-title">
					<a href={uri} data-hook="show-thumb-link">
						{parseHtml(title)}
					</a>
				</h5>
				{tuneIn && (
					<h6 className="show-thumb__time" data-hook="show-thumb-tunein">
						{parseHtml(tuneIn)}
					</h6>
				)}
			</div>
		</div>
	) : null;
};

ShowThumb.defaultProps = {
	width: '339',
	height: '451',
	className: 'is-6-mobile is-3-tablet is-2-desktop'
};

ShowThumb.propTypes = {
	title: string.isRequired,
	uri: string.isRequired,
	tuneIn: oneOfType([string, array]),
	thumbnail: object.isRequired,
	width: string,
	height: string,
	className: string
};

ShowThumb.displayName = 'ShowThumb';

export default ShowThumb;
