import React from 'react';
import { string } from 'prop-types';
import { usePageContext } from '@/components/layout/page/PageContext';
import StructuredData from './StructuredData';
import { APP_HOSTNAME } from '@/config/config';

/**
 * @function BreadcrumbList
 * @description generates a BreadcrumbList script tag for a Landing Page or a Detail Page
 * @param {String} props.landingName
 * @param {String} [props.detailUrl]
 * @returns {React.ReactElement}
 */
const BreadcrumbList = ({ landingName, detailUrl }) => {
	const { edition } = usePageContext();
	const homePageUrl = `https://${APP_HOSTNAME}${edition === 'us' ? '' : `/${edition}`}`;

	const itemListElement = [
		{
			'@type': 'ListItem',
			position: 1,
			item: {
				'@id': homePageUrl,
				name: 'Home'
			}
		}
	];

	if (landingName) {
		const landingUrl = `${homePageUrl}/${landingName.toLowerCase()}`;

		itemListElement.push({
			'@type': 'ListItem',
			position: 2,
			item: {
				'@id': landingUrl,
				name: landingName
			}
		});
		if (detailUrl) {
			const detailName = detailUrl.split('/').pop();

			itemListElement.push({
				'@type': 'ListItem',
				position: 3,
				item: {
					'@id': detailUrl,
					name: detailName
				}
			});
		}
	}

	const breadcrumbListData = {
		'@type': 'BreadcrumbList',
		itemListElement
	};

	return <StructuredData data={breadcrumbListData} />;
};

BreadcrumbList.propTypes = {
	landingName: string.isRequired,
	detailUrl: string
};

BreadcrumbList.displayName = 'BreadcrumbList';

export default BreadcrumbList;
