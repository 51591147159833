import React, { useState, useMemo, Fragment } from 'react';
import DropDownList from '@/components/drop-down-list/DropDownList';
import ClipsBySeason from './ClipsBySeason';
import { usePageContext } from '@/components/layout/page/PageContext';
import { string } from 'prop-types';
import './ShowClips.scss';

/**
 * @function ShowClips
 * @description provides grid of show clip links with a drop down menu to filter by season
 * @param {String} start the starting season value
 * @param {String} end the ending season value
 * @param {String} category the show's category tag
 * @returns {React.ReactElement}
 */
const ShowClips = ({ start, end, category }) => {
	const { edition, translations } = usePageContext();
	const [activeSeason, setActiveSeason] = useState(end);

	const seasonLabel = translations.SEASON;
	let ddClass = '';

	switch (edition) {
		case 'ar':
		case 'br':
		case 'co':
		case 'mx':
		case 've':
			ddClass = 'dropdown--md';
	}

	// use the start and end values to create an array to iterate over to make a drop down menu
	const startInt = parseInt(start);
	const endInt = parseInt(end);
	const seasons = useMemo(() => {
		const s = [];
		for (let i = endInt; i >= startInt; i--) {
			s.push(i.toString());
		}
		return s;
	}, []);

	return (
		<div className="show-clips">
			{seasons.length > 1 && (
				<DropDownList
					header={`${seasonLabel} ${end}`}
					className={ddClass}
					analyticsLocation="show-detail-page"
					analyticsName="show-clips-dropdown"
				>
					{({ toggle, setTitle }) => (
						<Fragment>
							{seasons.map((season, i) => (
								<button
									className={`dropdown-item ${
										activeSeason === season ? 'is-active' : ''
									}`}
									disabled={activeSeason === season}
									onClick={() => {
										toggle();
										setTitle(`${seasonLabel} ${season}`);
										setActiveSeason(season);
									}}
									key={`season-${season}`}
									data-analytics-linkname={`show-clips-dropdown-season-${season}`}
									data-analytics-linklocation="show-detail-page"
								>
									{seasonLabel} {season}
								</button>
							))}
						</Fragment>
					)}
				</DropDownList>
			)}

			<ClipsBySeason season={activeSeason} category={category} />
		</div>
	);
};

ShowClips.propTypes = {
	start: string.isRequired,
	end: string.isRequired,
	category: string.isRequired
};

ShowClips.displayName = 'ShowClips';

export default ShowClips;
