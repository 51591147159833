import React, { useState, useRef, useEffect } from 'react';
import chevron from '@/images/chevron.svg';
import SvgImage from '@/components/icons/SvgImage';
import { func, string } from 'prop-types';
import './DropDownList.scss';

/**
 * @function DropDownList
 * @description styled drop down select list
 * @param {Function} children the dropdown items to render; expected to be a list of 'button' or 'a' elements with 'dropdown-item' as a class name
 * @param {String} [header] the initial text to show for the drop down trigger
 * @param {String} [className]
 * @param {String} [alignment] how the element should be aligned on the page; right, left, center
 * @param {String} [analyticsLocation]
 * @param {String} [analyticsName]
 * @returns {React.ReactElement}
 */
export const DropDownList = ({
	children,
	header,
	className,
	alignment,
	analyticsLocation,
	analyticsName
}) => {
	const [on, setState] = useState(false);
	const [title, setTitle] = useState(header);
	const node = useRef();
	const handleClickOutside = (e) => {
		if (node.current.contains(e.target)) return;
		setState(false);
	};

	useEffect(() => {
		if (on) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [on]);

	useEffect(() => {
		setTitle(header);
	}, [header]);

	return (
		<div className={`is-${alignment}-dd ${className}`}>
			<div className={`dropdown ${on ? 'is-active' : ''}`} data-testid="dropdown">
				<button
					className="dropdown-header"
					onClick={(e) => {
						e.preventDefault();
						setState(!on);
					}}
					data-analytics-linkname={analyticsName}
					data-analytics-linklocation={analyticsLocation}
					data-testid="dropdown-header"
				>
					<span className="dropdown-header__text">{title}</span>
					<SvgImage image={chevron} className="dropdown-icon" />
				</button>
				<div
					className="dropdown-menu"
					id="dropdown-menu"
					role="menu"
					data-testid="dropdown-menu"
				>
					<div className="dropdown-content edit-title-bar__dropdown" ref={node}>
						{children({
							on,
							setTitle,
							toggle: () => {
								// toggle the dropdown active state
								setState(!on);
							}
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

DropDownList.defaultProps = {
	header: 'Select',
	className: '',
	alignment: 'right',
	analyticsLocation: '',
	analyticsName: ''
};

DropDownList.propTypes = {
	children: func.isRequired,
	header: string,
	className: string,
	alignment: string,
	analyticsLocation: string,
	analyticsName: string
};

DropDownList.displayName = 'DropDownList';

export default DropDownList;
