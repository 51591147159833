import React from 'react';
import { object, string } from 'prop-types';
import SvgImage from '@/components/icons/SvgImage';
import socialLinks from './socialLinks';
import './ShowSocialIcons.scss';

/**
 * @function ShowSocialIcons
 * @description provides a list of show social icons
 * @param {Object} [show] show object provided from page data
 * @param {String} [className]
 * @param {String} [analyticsLinkNameSuffix] for analytics; used in conjuction with trackingPrefix value in socialLinks.js
 * @param {String} [analyticsLinkLocation] for analytics
 * @returns {React.ReactElement|null}
 */
const ShowSocialIcons = ({ show, className, analyticsLinkNameSuffix, analyticsLinkLocation }) => {
	return (
		<div
			className={`${className}__social-container show-social__container column is-12-mobile is-narrow-tablet`}
		>
			<div className={`${className}__social show-social`}>
				{socialLinks(show).map(({ name, icon, url, trackingPrefix }) => {
					return url ? (
						<a
							className={`${className}__social-link show-social__link`}
							key={name}
							href={url}
							target="_blank"
							rel="noopener noreferrer"
							data-analytics-linkname={`${trackingPrefix}${analyticsLinkNameSuffix}`}
							data-analytics-linklocation={analyticsLinkLocation}
							data-testid={`${name}-social`}
						>
							<SvgImage
								className={`${className}__social-icon show-social__icon`}
								image={icon}
							/>
						</a>
					) : null;
				})}
			</div>
		</div>
	);
};

ShowSocialIcons.defaultProps = {
	className: '',
	analyticsLinkNameSuffix: ''
};

ShowSocialIcons.propTypes = {
	show: object,
	className: string,
	analyticsLinkNameSuffix: string,
	analyticsLinkLocation: string
};

ShowSocialIcons.displayName = 'ShowSocialIcons';

export default ShowSocialIcons;
