import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import MetaTags from '@/components/meta-tags/MetaTags';
import parseHtml from '@/utils/parseHtml';
import { usePageContext } from '@/components/layout/page/PageContext';
import { object } from 'prop-types';
import ShowDetailStructuredData from './ShowDetailStructuredData';
import BreadcrumbList from '@/components/structured-data/BreadcrumbList';

const ShowDetailHead = ({ showData }) => {
	if (!showData) return null;
	const { translations } = usePageContext();
	const { name, thumbnail, page, pageMetaData } = showData;
	const description =
		page?.metaDescriptionOverride ||
		`${translations.SHOW_DETAIL_META_DESCRIPTION_PREFIX}${name}${translations.SHOW_DETAIL_META_DESCRIPTION_SUFFIX}`;
	const title = page?.metaTitleOverride || name;

	const metaTagData = {
		title,
		description,
		ogType: 'website',
		thumbnailUri: thumbnail?.uri,
		options: {
			ogTags: true
		},
		customNameTags: {
			robots: 'max-image-preview:large'
		}
	};

	return (
		<Fragment>
			<Helmet>
				<title>{parseHtml(title)}</title>
			</Helmet>
			<MetaTags {...metaTagData} />
			<BreadcrumbList landingName="Shows" detailUrl={pageMetaData?.canonicalUrl} />
			<ShowDetailStructuredData showData={showData} />
		</Fragment>
	);
};

ShowDetailHead.propTypes = {
	showData: object.isRequired
};

ShowDetailHead.displayName = 'ShowDetailHead';

export default ShowDetailHead;
