import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import SHOW_BY_KEY from '@/graphql/queries/SHOW_BY_KEY';
import { MpsDataConfig } from '@/components/mps/MpsConfig';
import { usePageContext } from '@/components/layout/page/PageContext';
import { LandingPageContext } from '@/components/pages/landing/LandingPageContext';
import Area from '@/components/widgets/Area';
import Content from '@/components/pages/landing/content/Content';
import FeaturedShows from '@/components/shows-blocks/FeaturedShows';
import ShowDetailHead from './ShowDetailHead';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import ShowSocialIcons from '@/components/show-branding/ShowSocialIcons';
import ShowClips from './ShowClips/ShowClips';
import ShowHero from './ShowHero';
import Loading from '@/components/loading/Loading';
import { queryError } from '@/utils/errorHandling';
import DataLayer from '@/components/analytics/DataLayer';
import { buildAnalyticsObject } from '../landingPageHelpers';
import HrefLang from '@/components/i18n/hreflang/HrefLang';
import intlUrl from '@/components/i18n/intlUrl';
import TitleHeader from '@/components/title-header/TitleHeader';
import NielsenStaticDCR from '@/components/nielsen/NielsenStaticDCR';
import './ShowDetail.scss';

/**
 * @function ShowDetail
 * @description provides a Landing Page component for show detail pages
 * This component passes its props and some additional data into a context
 * @returns {React.ReactElement}
 */
const ShowDetail = () => {
	const {
		edition,
		pageNum,
		previewLayout = {},
		previewShowKey,
		show,
		translations,
		uppercaseEdition
	} = usePageContext();

	// fetch the data for this show page
	const variables = {
		showKey: previewShowKey || show,
		edition: uppercaseEdition
	};
	const response = useQuery(SHOW_BY_KEY, { variables });

	if (response?.loading) return <Loading />;
	if (response?.error) console.error(response.error.message);

	if (typeof response.data === 'undefined') {
		console.error('data undefined');
		return <Loading />;
	}

	const showData = response?.data?.showByKey;

	if (!showData) {
		throw queryError({
			id: show,
			message: 'Show Not Found',
			pageType: 'ShowDetail',
			redirectUrl: intlUrl(edition, '/shows'),
			status: 302
		});
	}

	// set the layout, showing a preview layout if it was provided
	let layout;
	if (previewLayout.length > 0) {
		// use layout provided from preview, if available
		layout = previewLayout;
	} else {
		// otherwise get the layout data from normal landing page query
		layout = showData.page?.layout || {};
	}

	const { pageMetaData } = showData;

	if (pageMetaData?.redirectUrl) {
		throw queryError({
			message: 'Page Moved Permanently',
			pageType: 'ShowDetail',
			redirectUrl: pageMetaData.redirectUrl,
			status: 301
		});
	}

	// show data
	const showColor = showData.brandHexColor1;
	// show social icons if any information provided
	const showSocials =
		!!showData.youtubeAccountUrl ||
		!!showData.facebookAccountUrl ||
		!!showData.instagramAccountUrl ||
		!!showData.twitterAccountUrl;

	const sections = layout?.sections || [];
	const noData = sections.length < 1;
	const advertiser = layout?.adKeywords || 'null';
	const showContentFeed = layout?.contentFeedEnabled == null ? true : layout.contentFeedEnabled;
	// parse the currentPage number from the pageNum url param
	const currentPage = parseInt(pageNum) || 1;
	// construct urlPrefix for pagination
	const urlPrefix = showData.uri;
	// remove leading slash from uri
	const basePath = showData.uri.substring(1);
	// Gather widget data
	const showWidgets = !noData;
	// the show category name for MPX query; can be different from internal category
	const category =
		showData.category?.name && showData.category.name.toLowerCase().replace(' ', '_');

	// only show the content feed header on page 1
	const showFeedHeader = currentPage === 1;

	const context = {
		advertiser,
		currentPage,
		noData,
		sections,
		showWidgets,
		showFeedHeader,
		translations,
		uppercaseEdition,
		urlPrefix,
		basePath, // path without protocol, edition, pagination, or trailing or leading slashes
		categoryKey: show, // formated from :show param in url
		limit: 25, // for contentLinkables query
		location: 'show-detail', // used for analytics in WidgetTrending
		metaTitle: showData.name, // for page's <title> tag
		// analytics values
		analyticsCategory: show,
		analyticsContentType: 'shows',
		analyticsPageType: 'show-detail',
		siteSection: 'shows'
	};

	const showPageData = buildAnalyticsObject(context);

	showPageData.contentInfo.pagewidgetType +=
		showPageData.contentInfo.pagewidgetType === ''
			? 'show-hero-section,featured-shows'
			: ',show-hero-section,featured-shows';
	if (showSocials) {
		showPageData.contentInfo.pagewidgetType += ',show-follow-section';
	}
	if (showData.seasonStart) {
		showPageData.contentInfo.pagewidgetType += ',show-clips';
	}

	return (
		<main className={`container show-detail${currentPage === 1 ? ' show-detail--first' : ''}`}>
			<Helmet>
				{showColor && (
					<style type="text/css">{`
						:root {
							--secondary-color: ${showColor};
							--widget-header-color: ${showColor};
							--widget-header-border-color: ${showColor};
						}
						.show-detail, .show-hero {
							--show-primary-color: ${showColor};
							--show-hero-color: ${showColor};
						}
						.show-social {
							--show-social-icons-color: ${showColor};
						}
					`}</style>
				)}
				<link
					rel="canonical"
					href={`${pageMetaData?.canonicalUrl}${
						currentPage > 1 ? `/page/${currentPage}` : ''
					}`}
				/>
			</Helmet>
			<ShowDetailHead showData={showData} />
			<MpsDataConfig topCategory={show} pageAdKeyword={layout?.adKeywords} />
			<DataLayer pageData={showPageData} />
			<HrefLang uri={pageMetaData?.defaultUrl} hrefLang={pageMetaData?.hreflang} />
			<NielsenStaticDCR assetId={`show-detail-${show}`} siteSection={`show-${show}`} />
			<LandingPageContext.Provider value={context}>
				<div className="show-detail">
					{currentPage === 1 && (
						<Fragment>
							<ShowHero showData={showData} isPrimaryHeader={true} />

							{showWidgets && <Area sections={sections} />}
							{showSocials && (
								<div
									data-hook="show-detail__section"
									className="show-detail__section"
								>
									<WidgetHeader
										title={`${translations.FOLLOW || 'Follow'} ${
											showData.name
										}`}
									/>
									<ShowSocialIcons
										show={showData}
										className="show-detail"
										analyticsLinkNameSuffix="-show"
										analyticsLinkLocation="show-detail-page"
									/>
								</div>
							)}

							{showData.seasonStart && showData.seasonEnd && category && (
								<Fragment>
									<WidgetHeader title={translations.CLIPS || 'Clips'} />
									<ShowClips
										start={showData.seasonStart}
										end={showData.seasonEnd}
										category={category}
									/>
								</Fragment>
							)}
						</Fragment>
					)}
					{currentPage !== 1 && (
						<TitleHeader
							className="show-detail__title"
							styleType="SECONDARY"
							title={showData?.name}
						/>
					)}

					{showContentFeed && <Content />}
					<FeaturedShows />
				</div>
			</LandingPageContext.Provider>
		</main>
	);
};

ShowDetail.displayName = 'ShowDetail';

export default ShowDetail;
