import React, { useState, useRef, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { usePageContext } from '@/components/layout/page/PageContext';
import VIDEOS from '@/graphql/queries/VIDEOS';
import Loading from '@/components/loading/Loading';
import ThumbTimestamp from '@/components/video/ThumbTimestamp';
import Thumb from '@/components/thumb/Thumb';
import ContentItemIcon from '@/components/icons/ContentItemIcon';
import Translate from '@/components/i18n/Translate';
import intlUrl from '@/components/i18n/intlUrl';
import parseHtml from '@/utils/parseHtml';
import NoContent from './NoContent';
import { string } from 'prop-types';
import './ClipsBySeason.scss';

/**
 * @function ShowClipsBySeason
 * @description provides grid of show clip links filtered by season
 * @param {String} season the season number to load
 * @param {String} category the show's category tag
 * @returns {React.ReactElement}
 */
const ShowClipsBySeason = ({ season, category }) => {
	const { edition, uppercaseEdition } = usePageContext();

	const [showLoadBtn, setShowLoadBtn] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	// holds the video items; flag as loading state before the query completes
	const [clips, setClips] = useState({ firstLoad: true });
	const totalClips = useRef(0);

	// load in 8 clips at a time
	const limitIncrement = 8;
	// query errors out above 25 items, so results need to be capped
	const limitMaxValue = 25;
	const variables = {
		category,
		edition: uppercaseEdition,
		season,
		skip: 0,
		limit: limitIncrement
	};
	const { error, loading, fetchMore } = useQuery(VIDEOS, {
		variables,
		onCompleted: (data) => {
			// assign data on complete to accurately update all information on season number change

			// total amount of results available
			const totalCount = data?.videos?.totalCount || 0;
			// video clip items
			const nodes = data?.videos?.nodes;
			// maximum that can be loaded on the page
			totalClips.current = Math.min(limitMaxValue, totalCount);
			// set show more button state
			setShowLoadBtn(nodes.length < totalClips.current);
			// set new clip data
			setClips(nodes);
		}
	});

	if (error) console.error(error.message);

	// load in more results
	const loadMore = (season) => {
		// cap query amount
		const max = totalClips.current;
		if (clips.length < max) {
			setLoadingMore(true);

			let limit = clips.length + limitIncrement;

			if (limit >= max) {
				limit = max;
				// hide the load more button if we can't query any more
				setShowLoadBtn(false);
			}

			fetchMore({
				variables: { limit }
			}).then((fetchMoreResult) => {
				setLoadingMore(false);
				setClips(fetchMoreResult?.data?.videos?.nodes);
			});
		} else {
			setShowLoadBtn(false);
		}
	};

	return (
		<div className="show-clips__columns columns is-mobile is-multiline text--sm">
			{error ? (
				<NoContent season={season} />
			) : loading || clips?.firstLoad ? (
				// loading for whole section call
				<div className="column is-12" data-hook="clips-loading" data-testid="clips-loading">
					<Loading />
				</div>
			) : (
				<Fragment>
					{clips && clips.length > 0 ? (
						<Fragment>
							{clips.map((item, index) => {
								const link = intlUrl(edition, item.uri);
								return (
									<div
										className="column is-12-mobile is-4-tablet is-3-desktop"
										key={`video-clip-${item.id}`}
										data-hook="season-clip"
										data-testid="season-clip"
									>
										<Thumb
											thumbnail={item.thumbnail}
											title={item.title}
											width="235"
											type="2x1"
											href={link}
											videoId={item.id}
										>
											<ContentItemIcon type="VIDEO" />
											{item.snipe && (
												<span className="show-clip__banner thumb-banner">
													<Translate word={item.snipe.toUpperCase()} />
												</span>
											)}
											<ThumbTimestamp duration={item.duration} />
										</Thumb>
										<p className="show-clip__title">
											<a href={link}>{parseHtml(item.title)}</a>
										</p>
									</div>
								);
							})}

							{/* loading while waiting for a loadMore call */}
							{loadingMore && (
								<div className="column is-12">
									<Loading />
								</div>
							)}

							{showLoadBtn && (
								<div className="column is-12 show-clips__button">
									<button
										className="button button--lrg"
										onClick={loadMore}
										data-hook="clips-load-more"
										data-testid="clips-load-more"
										data-analytics-linkname="show-clips-load-more-button"
										data-analytics-linklocation="show-detail-page"
									>
										<Translate word="LOAD_MORE" />
									</button>
								</div>
							)}
						</Fragment>
					) : (
						<NoContent season={season} />
					)}
				</Fragment>
			)}
		</div>
	);
};

ShowClipsBySeason.propTypes = {
	season: string.isRequired,
	category: string.isRequired
};

ShowClipsBySeason.displayName = 'ShowClipsBySeason';

export default ShowClipsBySeason;
